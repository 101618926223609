import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
  })
export class AuthenticationService {
  private token: string;

  private refreshToken: string;

  constructor() {
    this.initAuth();
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
    this.token = token;
  }

  setRefreshToken(refreshToken: string): void {
    localStorage.setItem('refreshToken', refreshToken);
    this.refreshToken = refreshToken;
  }

  loadToken(): string {
    this.token = localStorage.getItem('token');
    return this.token;
  }

  loadRefreshToken(): string {
    this.refreshToken = localStorage.getItem('refreshToken');
    return this.refreshToken;
  }

  clearToken(): void {
    localStorage.removeItem('token');
  }

  clearRefreshToken(): void {
    localStorage.removeItem('refreshToken');
  }

  clearAll(): void {
    localStorage.clear();
  }

  checkTokenExists(): boolean {
    return !!localStorage.getItem('token');
  }

  checkRefreshTokenExists(): boolean {
    return !!localStorage.getItem('refreshToken');
  }

  private initAuth() {
    this.loadToken();
    this.loadRefreshToken();
  }
}
