export const firebaseConfig = {
  apiKey: 'AIzaSyBopfNEzubS3_6c003LRmQ4HP8CoUIsmkQ',
  authDomain: 'agd-agro-3ef87.firebaseapp.com',
  databaseURL: 'https://agd-agro-3ef87.firebaseio.com',
  projectId: 'agd-agro-3ef87',
  storageBucket: 'agd-agro-3ef87.appspot.com',
  messagingSenderId: '491606300371',
  appId: '1:491606300371:web:e48482e252add2081da626',
  measurementId: 'G-NQC42M37HB'
};
