import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OfflinePageComponent } from './pages/offline-page/offline-page.component';
import { OfflineGuard } from './guards/offline.guard';

const routes: Routes = [
  {
    path: '',
    component: OfflinePageComponent,
    canActivate: [OfflineGuard],
    canDeactivate: [OfflineGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
  })
export class OfflineRoutingModule { }
