import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import * as dateHelper from '../../_shared/helpers/dates';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public api: string;

  public headers: HttpHeaders;

  constructor(
    public http: HttpClient,
    public authService: AuthenticationService
  ) {
    this.api = environment.API_URL;
    this.setDefaultHeaders();
  }

  public setDefaultHeaders(): void {
    this.headers = new HttpHeaders();
    this.headers
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    this.setAuthorizationHeader();
  }

  public setAuthorizationHeader(): void {
    const token = this.authService.loadToken();
    if (token) {
      this.headers = this.headers.set('Authorization', `Bearer ${token}`);
    }
  }

  public getHeaders(): HttpHeaders {
    return this.headers;
  }

  public get(url: string, params?: HttpParams): Observable<Object> {
    const opts = {
      headers: this.headers,
      params: null
    };

    if (params) {
      opts.params = params;
    }

    return this.http.get(`${this.api}${url}`, opts);
  }

  public getMock(url: string, params?: HttpParams): Observable<Object> {
    const opts = {
      headers: this.headers,
      params: null
    };

    if (params) {
      opts.params = params;
    }

    return this.http.get(`${url}`, opts);
  }

  public post(url: string, body: any): Observable<Object> {
    const opts = {
      headers: this.headers
    };

    return this.http.post(`${this.api}${url}`, body, opts);
  }

  public put(url: string, body: any): Observable<Object> {
    const opts = {
      headers: this.headers
    };

    return this.http.put(`${this.api}${url}`, body, opts);
  }

  public patch(url: string, body: any): Observable<Object> {
    const opts = {
      headers: this.headers
    };

    return this.http.patch(`${this.api}${url}`, body, opts);
  }

  public delete(url: string): Observable<Object> {
    const opts = {
      headers: this.headers
    };

    return this.http.delete(`${this.api}${url}`, opts);
  }

  public download(url: string, params?: HttpParams): Observable<Object> {
    const opts: Object = {
      headers: this.headers,
      params: null,
      responseType: 'blob'
    };

    if (params) {
      (opts as any).params = params;
    }

    return this.http.get(`${this.api}${url}`, opts);
  }

  public downloadFile(url: string): Observable<any> {
    const opts: any = {
      headers: this.headers,
      params: null,
      responseType: 'blob'
    };
    return this.http.get(url, opts);
  }

  public downloadAsString(url: string, params?: HttpParams): Observable<Object> {
    const opts = {
      headers: this.headers,
      params: null,
      // https://github.com/angular/angular/issues/18586#issuecomment-321333934
      responseType: 'text' as 'text'
    };

    if (params) {
      opts.params = params;
    }

    return this.http.get(`${this.api}${url}`, opts);
  }

  /**
   * Adds filters and params
   * @param filters filters to apply
   * @param httpParams params to apply
   */
  addFilterParams(filters, httpParams) {
    for (const item in filters) {
      if (filters.hasOwnProperty(item)) {
        if (filters[item] instanceof Date && !isNaN(filters[item].valueOf())) {
          httpParams = httpParams.append(item, dateHelper.formatDateParam(filters[item]));
        } else {
          httpParams = httpParams.append(item, filters[item]);
        }
      }
    }
    return httpParams;
  }

  /**
   * Adds XLS columns to params
   * @param filters filters to apply
   * @param httpParams params to apply
   */
  addXlsParams(columns: Array<any>, httpParams: HttpParams) {
    for (let i = 0; i < columns.length; i++) {
      httpParams = httpParams.append(`exportarXls[${i}]`, columns[i]);
    }
    return httpParams;
  }

  /**
   * Adds Array items to params
   * @param items items
   * @param arrayName array name
   * @param httpParams params to apply
   */
  addArrayParams(items: Array<any>, arrayName: String, httpParams: HttpParams) {
    let params = httpParams;
    for (let i = 0; i < items.length; i++) {
      params = params.append(`${arrayName}[${i}]`, items[i]);
    }
    return params;
  }
}
