/* eslint-disable no-param-reassign */
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { TableStatusEnum } from '../components/table-status/table-status.enum';
import { FormatCUITPipe } from '../modules/agd-pipes/format-cuit/pipes/format-cuit.pipe';

declare var $;

export type GridFormat = 'date' | 'date-wo-time' | 'wo-decimals' | 'w-decimals' | 'wo-decimals-or-separators' | 'array' | 'cuit';

export function fillGrid(component, service, getColumnMethod, getDataMethod?, getTotalsMethod?) {
  component.dataSource = [];
  component.tableStatus = TableStatusEnum.LOADING;
  if (component.totalsStatus) {
    component.totalsStatus = TableStatusEnum.LOADING;
    component.resetKpis();
  }
  component[service][getColumnMethod]()
    .subscribe(
      (res: any) => {
        component.roleColumns = res.resultColumns;
        component.dataColumns = component.roleColumns.map(c => c.nombreColumna);
        // If necessary, we add a column.
        if (component.dataColumnsDisplayed) {
          component.dataColumnsDisplayed = component.dataColumns.concat([component.addedColumnName]);
        }
        // Delete invalid role columns
        const columnKeys = Object.keys(component.possibleFields);
        component.headerColumns = [];
        columnKeys.forEach((c) => {
          const index = component.dataColumns.indexOf(component.possibleFields[c].filterName);
          if (index !== -1) {
            component.possibleFields[c].placeHolder = component.roleColumns[index].textoColumna;
            component.headerColumns[index] = {
              name: component.possibleFields[c].placeHolder,
              total: component.roleColumns[index].totalesColumna,
              orderBy: component.dataColumns[index],
              hasDropDown: component.possibleFields[c].hasDropDown,
              format: component.possibleFields[c].format,
              key: component.possibleFields[c].key,
              type: component.possibleFields[c].type,
              filterFrom: component.possibleFields[c].filterFrom,
              filterTo: component.possibleFields[c].filterTo,
              linkField: component.possibleFields[c].linkField,
              commonFilter: component.possibleFields[c].commonFilter
            };
            component.headerColumns[index].filter = c;
            component.headerColumns[index].isFilterField = !!component.possibleFields[c].key;
          }
        });
        if (getDataMethod) {
          component[getDataMethod]();
        }
        if (getTotalsMethod) {
          component[getTotalsMethod]();
        }
      },
      (err: any) => {
        console.error(err);
        component.tableStatus = TableStatusEnum.ERROR;
      }
    );
}

/**
 * Reset paginator values.
 * @param component component
 */
export function resetPaginator(component) {
  component.tableattr.page = 1;
  if (component.paginator) {
    component.paginator.pageIndex = 0;
  }
}

/**
 * Sets page tooltips.
 */
export function userTooltips() {
  $('body').tooltip({ selector: '[data-toggle=tooltip]' });
  $('[data-toggle="tooltip"]').tooltip({
    trigger: 'hover'
  });
  $('[data-toggle="tooltip"]').on('click', function hide() {
    $(this).tooltip('hide');
  });
}


export function formatGrid(format: string, cell: any) {
  if (cell !== undefined && cell !== null && cell !== '---') {
    switch (format as GridFormat) {
      case 'date':
        // Formatting dates
        return moment(cell).format('DD/MM/YYYY');
      case 'date-wo-time':
        // Formatting dates without time
        return moment(cell).format('DD/MM/YYYY');
      case 'wo-decimals': {
        // Formatting numbers without decimals
        const formatedcell = parseInt(cell, 10).toLocaleString('ES-ar');
        return formatedcell;
      }
      case 'w-decimals': {
        // Formatting numbers with the Latin format and decimals
        const transformedNumber = parseFloat(cell).toLocaleString('ES-ar', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return transformedNumber;
      }
      case 'wo-decimals-or-separators':
        // Formatting numbers without decimal or separator points.
        return cell.toString().split('.')[0];
      case 'array':
        return cell.join(', ') || '---';
      case 'cuit': {
        const cuitPipe = new FormatCUITPipe();
        return cuitPipe.transform(cell);
      }
      default:
        break;
    }
  }
  return cell;
}

export function getColumnTotal(component, column) {
  if (column !== undefined && column !== null) {
    return component[column];
  }
  return '';
}

/**
 * Gets rows data.
 * @param columns columns info
 * @param rowData selected row
 * @param dataColumns columns to display
 */
export function getRowDetails(columns: any, rowData: any, dataColumns: any) {
  const rowDetails = [];
  columns.forEach((column) => {
    if (dataColumns.find((item) => item === column.filter)) {
      rowDetails.push({
        column: column.filter,
        format: column.format,
        value: rowData[column.filter],
        name: column.name
      });
    }
  });
  return rowDetails;
}

/**
   * Returns formatted CUIT.
   * @param cuit cuit
   */
export function formatCUIT(cuit: string) {
  return `${cuit.substr(0, 2)}-${cuit.substr(2, 8)}-${cuit.substr(10, 1)}`;
}

/**
 * Return default products to filter.
 */
export function getDefaultProducts() {
  const products = ['SOJA', 'MAIZ', 'TRIGO', 'GIRASOL', 'SORGO'];
  return products;
}

/**
 * Return default 'fechas' to filter.
 */
export function getDefaultFechas() {
  const products = ['ÚLTIMA SEMANA', 'ÚLTIMO MES', 'ÚLTIMO AÑO'];
  return products;
}

/**
 * Unsubscribes all pending requests.
 */
export function completeSubscription(ngUnsubscribe) {
  ngUnsubscribe.next();
  ngUnsubscribe.complete();
}

/**
 * Returns a string representing a range.
 */
export function displayNumberRange(key: string, filterFrom: string, filterTo: string) {
  const minNumber = (key === 'kg') ? '0' : '-∞';
  return `${typeof filterFrom === 'number' ? filterFrom : minNumber} - ${typeof filterTo === 'number' ? filterTo : '∞'}`;
}

/**
 * If page is equal to url returns true.
 * Otherwise, returns false.
 * @param page page
 * @param url url
 * @param exact exact page
 */
export function isPageSelected(page: string, url: string, exact: boolean): boolean {
  // clear params from url
  const paramsIndex = url.indexOf('?');
  const clearUrl = url.slice(0, paramsIndex > -1 ? paramsIndex : undefined);

  return exact ? clearUrl === page : clearUrl.startsWith(page);
}

/**
 * Sort an array based on parameters
 * @param array array to order
 * @param orderBy selected key
 * @param order asc or desc
 */
export function sortByKey(array: any, orderBy: string, order: string) {
  if (order.toUpperCase() === 'ASC') {
    array.sort((a, b) => {
      const x = a[orderBy];
      const y = b[orderBy];
      if ((x < y)) {
        return -1;
      } if ((x > y)) {
        return 1;
      }
      return 0;
    });
  } else {
    array.sort((a, b) => {
      const x = a[orderBy];
      const y = b[orderBy];
      if ((x > y)) {
        return -1;
      } if ((x < y)) {
        return 1;
      }
      return 0;
    });
  }
  return array;
}
