import { Injectable } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserDataService } from '../../authentication/user-data.service';

@Injectable({
  providedIn: 'root'
  })
export class LoggedInGuard implements CanActivate {
  constructor(public userDataService: UserDataService, public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkUserIsLoggedIn(state, next);
  }

  checkUserIsLoggedIn(stateSnapshot: RouterStateSnapshot, next: ActivatedRouteSnapshot) {
    if (this.userDataService.userIsLoggedIn()) {
      return true;
    }

    if (stateSnapshot.url.includes('cuit')) {
      // full url without params
      const [returnUrl] = stateSnapshot.url.split('?');

      this.router.navigate(['/login'], { queryParams: { returnUrl, cuit: next.queryParams.cuit } });
      return false;
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: stateSnapshot.url } });
    return false;
  }
}
