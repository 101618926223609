import * as moment from 'moment';
import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

const SUPPORTS_INTL_API = typeof Intl !== 'undefined';

export function formatDateParam(date: Date) {
  return moment(date, 'YYYY-MM-DD[T]HH:mm:ss').format('DD-MM-YYYY');
}

/**
 * Receives a date and returns moment based on format parameter.
 * @param date date
 * @param format format
 */
export function formatDate(date: Date, format: string) {
  return moment(date, 'YYYY-MM-DD[T]HH:mm:ss').locale('es').format(format);
}

/**
 * Return a date based on the amount of days we have to subtract.
 * @param days amount of days to subtract.
 */
export function getFromDate(days: number): Date {
  const resultDate = new Date();
  resultDate.setDate(resultDate.getDate() - days);
  return resultDate;
}

@Injectable()
export class DateFormat extends NativeDateAdapter {
  useUtcForDisplay = true;

  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('-') > -1)) {
      const str = value.split('-');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }
}

/**
 * Returns a string representing a date range using the format passed.
 */
export function displayRange(from: Date, to: Date, format = 'DD/MM/YYYY') {
  return `${from ? moment(from).format(format) : '-∞'} - ${to ? moment(to).format(format) : '∞'}`;
}
