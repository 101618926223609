import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HttpParams } from '@angular/common/http';
import * as dateHelper from '../../../_shared/helpers/dates';
import { UserDataService } from '../../authentication/user-data.service';
import { GridQueriesService } from '../utils/grid-queries.services';

@Injectable({
  providedIn: 'root'
  })
export class RetentionsService {
  constructor(
    private http: ApiService,
    private userDataService: UserDataService,
    private gridQueriesService: GridQueriesService
  ) { }

  /**
   * Service for Retention List
   * @param page page
   * @param count count
   * @param from from
   * @param to to
   * @param orderBy orderBy
   * @param order order
   * @param filters filters
   */
  listRetentions(page: number, count: number, from: string, to: string,
    orderBy: string, order: string, filters: any) {
    return this.gridQueriesService.getGridData('documentacion/retenciones', {
      page,
      count,
      from,
      to,
      orderBy,
      order,
      filters,
    });
  }

  /**
   * Service for Retentions Totals
   * @param from from
   * @param to to
   * @param filters filters
   */
  listRetentionsTotals(from: Date, to: Date, filters: any) {
    let params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('desde', from ? dateHelper.formatDateParam(from) : '')
      .set('hasta', to ? dateHelper.formatDateParam(to) : '');

    params = this.http.addFilterParams(filters, params);

    return this.http.get('documentacion/retenciones-totales', params);
  }

  /**
   * Download a XLS associated with this service.
   * @param from from
   * @param to to
   * @param orderBy order by
   * @param order order
   * @param filters filters
   * @param columns columns
   */
  downloadXls(from: string, to: string, orderBy: string, order: string, filters: any,
    columns: Array<string>) {
    return this.gridQueriesService.downloadXls('documentacion/retenciones', {
      from,
      to,
      orderBy,
      order,
      filters,
      columns
    });
  }

  /**
   * Gets column list for 'retenciones' grid based on the active user's cuit
   */
  listRetentionsColumns() {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT());
    return this.http.get('usuarios/modulos/retenciones', params);
  }

  /**
   * Gets the data for the claims tutorial.
   */
  getClaimTutorial() {
    return this.http.get('documentacion/retenciones/reclamos/tutorial');
  }
}
