import { Directive, HostListener, ElementRef } from '@angular/core';
// Directiva para impedir que se ingrese 'e' o 'E' en los inputs de solo nombre
@Directive({
  selector: '[onlyNumbers]'
  })
export class onlyNumbersDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    const value = event.data;

    this.onChange(value, event);
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const clipboardValue = event.clipboardData.getData('text');
    if (clipboardValue?.includes('e') || clipboardValue?.includes('E')) event.preventDefault();
  }

  private onChange(value: string, event: InputEvent | ClipboardEvent) {
    const inputElement = this.el.nativeElement;
    // Remove 'e' or 'E' if it's present in the input value
    if (value?.includes('e') || value?.includes('E')) {
      inputElement.value = value.replace(/[eE]/g, '');
      event.preventDefault(); // Prevent the character from being input
    }
  }
}
