import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, Router } from '@angular/router';
import { OfflineService } from '../services/offline.service';

@Injectable({
  providedIn: 'root'
  })
export class OfflineGuard implements CanActivate, CanDeactivate<unknown> {
  constructor(private offlineService: OfflineService, private router: Router) { }

  canActivate() {
    const { isOffline } = this.offlineService;

    if (!isOffline) {
      this.router.navigateByUrl('/');
    }

    return isOffline;
  }

  canDeactivate() {
    return !this.offlineService.isOffline;
  }
}
