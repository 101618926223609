import { AlertComponent } from './components/alert/alert.component';
import { SelectFileComponent } from './components/select-file/select-file.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownButtonComponent } from './components/dropdown-button/dropdown-button.component';
import { BadgesComponent } from './components/badges/badges.component';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ChartsModule } from 'ng2-charts';
import { ListFiltersModalComponent } from './components/list-filters-modal/list-filters-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableStatusComponent } from './components/table-status/table-status.component';
import { FormatNumberPipe } from './pipes/format-number/format-number.pipe';
import { AddCuitModalComponent } from './components/add-cuit-modal/add-cuit-modal.component';
import { TextMaskModule } from 'angular2-text-mask';
import { RowDetailComponent } from './components/row-detail/row-detail.component';
import { PayOrderDatailsPageComponent } from './components/pay-order-datails-page/pay-order-datails-page.component';
import { ContractsToSetPageComponent } from './components/contracts-to-set-page/contracts-to-set-page.component';
import { TotalsStatusComponent } from './components/totals-status/totals-status.component';
import { ApplicationsPageComponent } from './components/applications-page/applications-page.component';
import { ContractsPageComponent } from './components/contracts-page/contracts-page.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { BrowserDetectorComponent } from './components/browser-detector/browser-detector.component';
import { NoActiveCuitsPageComponent } from './components/no-active-cuits-page/no-active-cuits-page.component';
import {
  RomaneosDeContratosAFijarPageComponent
} from './components/romaneos-de-contratos-a-fijar-page/romaneos-de-contratos-a-fijar-page.component';
import { AddPhoneModalComponent } from './components/add-phone-modal/add-phone-modal.component';
import { ValidatePhonePageComponent } from './components/validate-phone-page/validate-phone-page.component';
import { ValidatePhoneComponent } from './components/validate-phone/validate-phone.component';
import { AddOficialDeCuentasModalComponent } from './components/add-oficial-de-cuentas-modal/add-oficial-de-cuentas-modal.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { EticaMessageModalComponent } from './components/etica-message-modal/etica-message-modal.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ConfirmDeleteModalComponent } from './components/confirm-delete-modal/confirm-delete-modal.component';
import { PreciosPopoverComponent } from './components/precios-popover/precios-popover.component';
import { HoverTriggerDirective } from './directives/hover-trigger-directive/hover-trigger.directive';
import { ModalMessagesComponent } from './components/modal-messages/modal-messages.component';
import { LayoutModule } from '@angular/cdk/layout';
import { EmojiSelectorComponent } from './components/emoji-selector/emoji-selector.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { AgripagoLinkComponent } from './components/agripago-link/agripago-link.component';
import { SelectDropdownComponent } from './components/select-dropdown/select-dropdown.component';
import { SelectImageComponent } from './components/select-image/select-image.component';
import { ProgressComponent } from './components/progress/progress.component';
import { DndDirective } from './directives/drag-and-drop/dnd.directive';
import { ConfirmLinkComponent } from './components/confirm-link/confirm-link.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormatCUITPipeModule } from './modules/agd-pipes/format-cuit/format-cuit.module';
import { NotificationsModule } from './modules/agd-components/notifications/notifications.module';
import { FiltersModule } from './modules/agd-components/filters/filters.module';
import { TableColumnModule } from './modules/agd-components/table-column/table-column.module';

@NgModule({
  declarations: [
  DropdownButtonComponent,
  ListFiltersModalComponent,
  TableStatusComponent,
  TotalsStatusComponent,
  FormatNumberPipe,
  BadgesComponent,
  AddCuitModalComponent,
  RowDetailComponent,
  PayOrderDatailsPageComponent,
  ContractsToSetPageComponent,
  ApplicationsPageComponent,
  ContractsPageComponent,
  ConfirmDeleteModalComponent,
  SearchBarComponent,
  BrowserDetectorComponent,
  NoActiveCuitsPageComponent,
  RomaneosDeContratosAFijarPageComponent,
  AddPhoneModalComponent,
  ValidatePhonePageComponent,
  ValidatePhoneComponent,
  AddOficialDeCuentasModalComponent,
  EticaMessageModalComponent,
  PreciosPopoverComponent,
  HoverTriggerDirective,
  PreciosPopoverComponent,
  HoverTriggerDirective,
  ModalMessagesComponent,
  EmojiSelectorComponent,
  AgripagoLinkComponent,
  SelectDropdownComponent,
  SelectImageComponent,
  ProgressComponent,
  SelectFileComponent,
  DndDirective,
  AlertComponent,
  ConfirmLinkComponent,
  PaginatorComponent,
  ],
  imports: [
  CommonModule,
  MatMenuModule,
  MatIconModule,
  RouterModule,
  MatTableModule,
  MatSortModule,
  MatSelectModule,
  MatPaginatorModule,
  MatFormFieldModule,
  MatDialogModule,
  MatInputModule,
  MatNativeDateModule,
  FormsModule,
  MatDatepickerModule,
  MatProgressSpinnerModule,
  BsDropdownModule.forRoot(),
  ReactiveFormsModule,
  TextMaskModule,
  MatTabsModule,
  MatSnackBarModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatBadgeModule,
  ChartsModule,
  MatButtonModule,
  LayoutModule,
  PickerModule,
  MatRippleModule,
  MatTooltipModule,
  FormatCUITPipeModule,
  NotificationsModule,
  FiltersModule,
  TableColumnModule,
  FiltersModule,
  ],
  exports: [
  DropdownButtonComponent,
  TableStatusComponent,
  TotalsStatusComponent,
  FormatNumberPipe,
  BadgesComponent,
  RowDetailComponent,
  ContractsToSetPageComponent,
  ApplicationsPageComponent,
  ContractsPageComponent,
  SearchBarComponent,
  BrowserDetectorComponent,
  RomaneosDeContratosAFijarPageComponent,
  PreciosPopoverComponent,
  HoverTriggerDirective,
  SelectDropdownComponent,
  EmojiSelectorComponent,
  SelectImageComponent,
  ProgressComponent,
  SelectFileComponent,
  AlertComponent,
  PaginatorComponent,
  FormatCUITPipeModule,
  NotificationsModule,
  ],
  providers: [],
  })
export class SharedModule { }
