import { Injectable } from '@angular/core';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private analytics = firebase.analytics();

  navigationRegister(page_path: string) {
    this.analytics.logEvent('page_view', { page_location: page_path });
  }

  sendUserData(name: string) {
    // Send username for users report purposes
    this.analytics.setUserId(name);

    // Send username to see in real time who is browsing.
    // user_info it's a custom event for GA4.
    this.analytics.logEvent('user_info', {
      username_id: name
    });
  }
}
