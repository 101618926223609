import {
  Component, Input, OnInit, OnDestroy
} from '@angular/core';
import {
  FilterTypes, FiltersValues
} from '../../models/filters.types';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatRangeDateSelectionModel
} from '@angular/material/datepicker';
import { AppAnimations } from 'src/app/_shared/animations/animations';
import { FiltersAbstract } from '../classes/filters.abstract';
import { FiltersService } from '../../services/filters.service';

@Component({
  selector: 'agd-filters-menu',
  templateUrl: './filters-menu.component.html',
  styleUrls: ['./filters-menu.component.scss'],
  animations: [AppAnimations.fadeIn, AppAnimations.fadeOut],
  providers: [
  {
  provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
  useClass: DefaultMatCalendarRangeStrategy
  },
  DefaultMatCalendarRangeStrategy,
  MatRangeDateSelectionModel
  ]
  })
export class FiltersMenuComponent extends FiltersAbstract implements OnInit, OnDestroy {
  @Input() filterLabel = 'Filtrar por';

  constructor(
    filtersService: FiltersService,
    private readonly selectionModel: MatRangeDateSelectionModel<Date>,
    private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
  ) {
    super(filtersService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // Subscribe to filter configuration changes
    this.subs.sink = this.filtersService.filtersConfig.subscribe(config => {
      this.config = config;
      this.updateValueCount();
    });

    // Subscribe to values updates
    this.subs.sink = this.filtersService.filtersValues.subscribe(() => {
      const values: FiltersValues = JSON.parse(JSON.stringify(this.values));

      Object.keys(values).forEach(key => {
        const filterConfig = this.config[key];
        const filterValue = values[key];

        // mat-calendar uses DateRange class as selected value
        if (filterValue.value && filterConfig?.type === 'date-range') {
          const start = new Date(filterValue.value.start);
          const end = new Date(filterValue.value.end);
          values[key].value = new DateRange(start, end);
        }
      });

      this._currentValues = values;
      this.updateValueCount();
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  applyFilter(key: string, value?: any) {
    let newValue;

    if (value == null) {
      newValue = this._currentValues[key].value;
    } else {
      newValue = value;
    }

    this.filtersService.applyFilter(key, newValue);
  }

  /**
   * Determines if the filter should show the "apply" button
   * @param type type of the filter to evaluate
   * @returns true if the type matches to "text", "number", "number-range", "date" or "date-range"
   */
  withApplyAction(type: FilterTypes) {
    const withApplyButton: FilterTypes[] = ['text', 'number', 'number-range', 'date', 'date-range'];
    return withApplyButton.includes(type);
  }

  onDateRangeChange(key: string, selectedDate: Date) {
    const { selection } = this.selectionModel;
    const newSelection = this.selectionStrategy.selectionFinished(selectedDate, selection);

    this.selectionModel.updateSelection(newSelection, this);

    const value = new DateRange(newSelection.start, newSelection.end);

    this.initFilterRecord(key, value);

    this._currentValues[key].applyDisabled = !this.selectionModel.isComplete();
  }

  applyCheckboxValue(key: string, checked: boolean, value: string | number | boolean) {
    const updatedValue: any[] = this._currentValues?.[key]?.value || [];

    if (checked && !updatedValue.includes(value)) { // add value
      updatedValue.push(value);
    } else if (!checked && updatedValue.includes(value)) { // remove value
      const index = updatedValue.indexOf(value);
      if (index > -1) {
        updatedValue.splice(index, 1);
      }
    }

    this.applyFilter(key, updatedValue);
  }

  isCheckedValue(key: string, value) {
    return this._currentValues?.[key]?.value?.includes?.(value);
  }
}
