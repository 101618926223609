import { Injectable } from '@angular/core';
import firebase from 'firebase';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { ApiService } from '../../http/api.service';
import { firebaseConfig } from 'src/config';

@Injectable({
  providedIn: 'root'
  })
export class FirebaseMessagingService {
  currentMessage = new BehaviorSubject(null);

  private messaging;

  constructor(private http: ApiService) {
    firebase.initializeApp(firebaseConfig);
    // we set subscriptions to firebase messaging only if client navigator supports it.
    if (this.firebaseSupported()) {
      this.messaging = firebase.messaging();

      this.messaging.onMessage((notification) => {
        this.currentMessage.next(notification);
      });

      this.messaging.onTokenRefresh(() => {
        this.requestPermissions();
      });

      navigator.serviceWorker.register('firebase-messaging-sw.js')
        .then((registration) => {
          this.messaging.useServiceWorker(registration);
        });
    }
  }

  firebaseSupported(): boolean {
    return firebase.messaging.isSupported();
  }

  onNewFirebaseMessage() {
    return this.currentMessage.asObservable();
  }

  requestPermissions(): Observable<any> {
    return from(this.messaging.requestPermission()
      .then(() => this.messaging.getToken())
      .catch((err: any) => {
        console.error(err);
      }));
  }

  registerFirebaseToken(fcmToken: string) {
    const body = {
      fcmToken,
      isMobile: false
    };
    return this.http.post('usuarios/verificar-dispositivo', body);
  }
}
