import { ReclamoFinalizarModalComponent } from '../components/reclamo-finalizar-modal/reclamo-finalizar-modal.component';
import { TicketMessage, TicketData } from '../interfaces/reclamos.interfaces';
import { HttpParams } from '@angular/common/http';
import {
  AsyncSubject, Observable
} from 'rxjs';
import { ApiService } from '../../../../../_core/http/api.service';
import { UserDataService } from '../../../../../_core/authentication/user-data.service';
import { AyudaService } from 'src/app/_shared/modules/agd-components/ayuda/services/ayuda.service';
import { ReclamoAyudaComponent } from '../components/reclamo-ayuda/reclamo-ayuda.component';
import { ReclamoConfirmacionComponent } from '../components/reclamo-confirmacion/reclamo-confirmacion.component';
import { ReclamoFormularioComponent } from '../components/reclamo-formulario/reclamo-formulario.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ReclamoDetalleComponent } from '../components/reclamo-detalle/reclamo-detalle.component';
import { map } from 'rxjs/operators';
import { USER_ROLES } from 'src/app/_shared/helpers/applicationConstants';
import { Motivo } from '../../reclamos/models/motivo-consulta.model';
import { SubMotivo } from '../models/submotivo-consulta.model';

export interface ComplaintData {
  [key: string]: any;
  nroReclamo: number;
  fecha: string;
  mail: string;
}

export interface ComplaintFormData {
  nroRetencion: string;
  detalle: string;
  adjuntos?: File[];
}

interface ComplaintGetQueryParams {
  pagina: number;
  cantidad: number;
  ordenadoPor: string;
  orden: string;
  fechaActualizacionDesde: string;
  fechaActualizacionHasta: string;
  filtros?: Record<string, string>;
  soloGrilla?: boolean;
}

export interface TicketFormData {
  descripcion: string;
  cuit?: string;
  tipoDocumento?: string;
  nroDocumento?: string;
  adjunto?: File;
  subMotivo?: number;
}

type TicketPostBody = TicketFormData & { userId?: number };

@Injectable({
  providedIn: 'root',
  })
export class ReclamosService {
  get complaintData() {
    return ((this._complaintData && JSON.parse(JSON.stringify(this._complaintData))) as ComplaintData) || null;
  }

  get retentionRef() {
    return (this._retentionRef && JSON.parse(JSON.stringify(this._retentionRef))) || null;
  }

  private _complaintData: ComplaintData;

  private _retentionRef: any;

  private _complaintModalRef: MatDialogRef<any>;

  private _helpModalRef: MatDialogRef<any>;

  private _confirmationModalRef: MatDialogRef<any>;

  private _complaintDetailModalRef: MatDialogRef<any>;

  private _complaintOpened: AsyncSubject<boolean>;

  private _finalizeTicketModalRef: MatDialogRef<any>;

  constructor(
    private dialog: MatDialog,
    private ayudaService: AyudaService,
    private userDataService: UserDataService,
    private http: ApiService,
  ) { }

  /**
   * Opens a new complaint form modal
   * @param retentionData reference data of the retention
   * @returns {Observable} an Observable that completes when the opened complaint is actually sended to the server
   */
  openNewComplaint(retentionData: any): Observable<boolean> {
    this._retentionRef = retentionData;

    this._complaintModalRef = this.dialog.open(ReclamoFormularioComponent, {
      maxHeight: '90vh',
      autoFocus: false,
      id: 'complaint-modal-form',
      panelClass: 'no-min-width'
    });

    this._complaintOpened = new AsyncSubject();

    return this._complaintOpened.asObservable();
  }

  sendNewComplaint(data: ComplaintFormData) {
    const cuit = this.userDataService.getActiveCUIT();

    const formData = new FormData();

    formData.append('nroRetencion', data.nroRetencion);
    formData.append('detalle', data.detalle);

    data.adjuntos?.forEach(file => {
      formData.append('adjuntos', file);
    });

    return this.http.post(`documentacion/retenciones/reclamos?cuit=${cuit}`, formData);
  }

  checkExistence(retention: string) {
    const params = new HttpParams()
      .set('cuit', this.userDataService.getActiveCUIT())
      .set('nroRetencion', retention);

    return this.http.get('documentacion/retenciones', params).pipe(
      map((response: any) => {
        if (!response.retenciones || response.retenciones.length === 0) return null;

        return response.retenciones[0].reclamo || null;
      })
    );
  }

  /**
   * closes the current opened complaint form modal
   * @param role reason why modal is closed, default: 'cancelled'
   */
  closeComplaintModal(role = 'cancelled') {
    this._complaintModalRef?.close({ role });
  }

  showConfirmation(complaintData: ComplaintData) {
    this._complaintData = complaintData;

    this._complaintModalRef?.close();
    this._confirmationModalRef = this.dialog.open(ReclamoConfirmacionComponent, {
      maxHeight: '90vh',
      autoFocus: false,
      id: 'modal-reclamos-confirmacion',
      data: complaintData,
    });

    this._complaintModalRef.beforeClosed().subscribe(() => {
      this._complaintOpened.next(true);
      this._complaintOpened.complete();
    });
  }

  showDeadlines() {
    this._complaintModalRef?.addPanelClass('d-none');
    this._complaintDetailModalRef?.addPanelClass('d-none');

    this._helpModalRef = this.ayudaService.showHelpModal(ReclamoAyudaComponent);

    this._helpModalRef.afterClosed().subscribe(() => {
      this._complaintModalRef?.removePanelClass('d-none');
      this._complaintDetailModalRef?.removePanelClass('d-none');
    });
  }

  closeDeadlinesModal() {
    this._helpModalRef?.close();
    this._complaintModalRef?.removePanelClass('d-none');
  }

  visualizeComplaintDetail(retentionData: any) {
    this._complaintData = retentionData.reclamo;
    this._retentionRef = retentionData;

    this._complaintDetailModalRef = this.dialog.open(ReclamoDetalleComponent, {
      maxHeight: '90vh',
      autoFocus: false,
      id: 'modal-reclamos-confirmacion',
    });
  }

  closeConfirmation(role = 'closed') {
    this._confirmationModalRef?.close({ role });
  }

  closeComplaintDetailModal(role: string) {
    this._complaintDetailModalRef?.close({ role });
  }

  isAfterTheDeadline(fechaRetencion: string | Date, fecha: string | Date) {
    const date = moment(fechaRetencion, 'YYYY-MM-DD');
    const warningDate = moment(date);

    if (date.date() <= 15) {
      // set fisrt day of second 1/2 of the month
      warningDate.startOf('day').set('date', 16);
    } else {
      warningDate.add(1, 'M').startOf('month');
    }

    warningDate.add(3, 'd');

    // If date is on weekend add days until next monday
    if (warningDate.weekday() > 5) {
      // if saturday add 2 days else add 1
      const days = warningDate.weekday() === 6 ? 2 : 1;
      warningDate.add(days, 'd');
    }

    // If current date is greater than warning date
    return warningDate.isBefore(moment(fecha, 'YYYY-MM-DD'));
  }

  getTicketsList(queryParams: ComplaintGetQueryParams) {
    const { filtros, ...otherParams } = queryParams;
    const allFilters: Record<string, any> = { ...filtros, ...otherParams };

    Object.keys(allFilters).forEach(elem => {
      if (allFilters[elem] === undefined || allFilters[elem] === null) {
        delete allFilters[elem];
      }
    });

    // Check if user has help desk role
    const hasHelpDeskRole = this.userDataService.hasPermission(USER_ROLES.MESA_DE_AYUDA);
    let params = new HttpParams({ fromObject: allFilters });
    if (!hasHelpDeskRole) {
      const cuit = this.userDataService.getActiveCUIT();
      params = params.set('cuit', cuit);
    }

    return this.http.get('reclamos', params).pipe(map((response: any) => {
      const tickets = response.tickets?.map((ticket) => {
        const ticketData: TicketData = {
          ...ticket,
          feedback: ticket.feedbackValue != null ? !!ticket.feedbackValue : null
        };

        return ticketData;
      }) || [];

      return {
        ...response,
        tickets,
      };
    }));
  }

  /**
   * send a request to create a new ticket
   * @param ticketFormData form data to be registered
   */
  createTicket(ticketFormData: TicketFormData) {
    const { adjunto, ...data } = ticketFormData;

    const user = this.userDataService.getUser();

    const ticketData: TicketPostBody = { userId: user.id, ...data };

    const formData = new FormData();

    Object.keys(ticketData).forEach((key) => {
      formData.append(key, ticketData[key]);
    });

    if (adjunto) {
      const file = this.getAttachmentFileObj(adjunto);

      const blob = file.file.slice(0, file.file.size, file.file.type);
      const document = new File([blob], file.filename, { type: blob.type });


      formData.append('adjunto', document);
    }

    return this.http.post('reclamos/', formData);
  }

  /**
   * Get a dictionary with all available types of document for a ticket
   */
  getDocumentTypes(moduloId: string, grillaId: string): Observable<Record<string, string>> {
    const params = new HttpParams({ fromObject: { moduloId, grillaId } });

    return this.http.get('reclamos/tipos-de-documento', params) as Observable<Record<string, string>>;
  }

  getTicketDetailData(ticketId: number): Observable<TicketData> {
    const userId = this.userDataService.user.id;

    return this.http.get(`reclamos/${ticketId}`).pipe(map((response: any) => {
      const mensajes = response.mensajes?.map((mensaje) => {
        const message: TicketMessage = {
          ...mensaje,
          usuario: mensaje.user.username,
          nombre: mensaje.user.nombre,
          apellido: mensaje.user.apellido,
          visto: userId === mensaje.user.user_id || mensaje.visto,
          respuesta: response.authorId !== mensaje.user.user_id
        };
        return message;
      }) || [];

      const ticketData: TicketData = {
        ...response,
        mensajes,
        feedback: response.feedbackValue != null ? !!response.feedbackValue : null
      };

      return ticketData;
    }));
  }

  /**
   * Download attachment file related to ticket message
   * @param url url to get attachment file from BE.
   * @param token token to validate the user authorization.
   */
  downloadAttachmentFileMessage(url: string) {
    return this.http.downloadFile(url);
  }

  /**
   * Presents a confirmation modal to close a ticket.
   * If user accepts, a request is sent to the server.
   * @param ticketId reference id to be closed
   * @returns promise that resolves when the request is completed
   */
  finalizeTicketConfirm(ticketId: number) {
    this._finalizeTicketModalRef = this.dialog.open(ReclamoFinalizarModalComponent, {
      autoFocus: false,
      id: 'finalize-ticket-modal',
      backdropClass: 'blurred-backdrop',
      panelClass: ['light-box-shadow-modal', 'no-min-width'],
      width: '325px'
    });

    return this._finalizeTicketModalRef.afterClosed().toPromise().then(result => {
      if (result) {
        return this.closeTicket(ticketId).toPromise().then(() => true, () => false);
      }

      return false;
    });
  }

  confirmTicketClosure(confirmed: boolean) {
    this._finalizeTicketModalRef?.close(confirmed);
  }

  sendTicketComment(ticketId: number, comment: string, attachment?: File) {
    const fileData = new FormData();

    fileData.append('descripcion', comment);

    if (attachment) {
      const file = this.getAttachmentFileObj(attachment);

      const blob = file.file.slice(0, file.file.size, file.file.type);
      const document = new File([blob], file.filename, { type: blob.type });

      fileData.append('adjunto', document);
    }

    return this.http.post(`reclamos/${ticketId}/mensaje`, fileData);
  }

  /**
   * Sends a request to the server to mark, as seen, all messages to the current user
   * @param ticketId reference id
   */
  markMessagesAsSeen(ticketId: number): Observable<any> {
    return this.http.put(`reclamos/${ticketId}/mensajes/marcar-como-visto`, null);
  }

  /**
   * Send a request to the server for provide feedback related to ticket
   * @param ticketId
   * @param feedback
   */
  sendFeedback(ticketId: number, feedback: boolean) {
    return this.http.post(`reclamos/${ticketId}/feedback`, { feedbackValue: feedback ? 1 : 0 });
  }

  /**
  * Gets complete list of 'motivos'
  * @returns Observable with the list of available motivos
  */
  getMotivos() {
    return this.http
      .get('motivos-consultas/motivos')
      .pipe(map((result: any) => (result?.motivos && result?.motivos.map((m) => new Motivo(m))) || []));
  }

  /**
  * Gets the list of 'submotivos' of the selected 'motivo'
  * @param motivoId - motivo id
  * @returns Observable with the list of available 'submotivos' of an specific 'motivo'.
  */
  getSubmotivos(motivoId: string) {
    return this.http
      .get(`motivos-consultas/submotivos/${motivoId}`)
      .pipe(map((result: any) => result?.subMotivos?.map((subM) => new SubMotivo(subM)) || []));
  }

  private getAttachmentFileObj(file: File) {
    const filename = `ticket-attachment-${moment().valueOf()}.${file.name.split('.').pop() || 'png'}`;
    return {
      filename, file
    };
  }

  private closeTicket(ticketId: number) {
    return this.http.post(`reclamos/cerrar-ticket/${ticketId}`, null);
  }
}
